.drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container:hover,
  .drop-container.drag-active {
    background: #eee;
    border-color: #111;
  }
  
  .drop-container:hover .drop-title,
  .drop-container.drag-active .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }

  .itemStyle{
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
  }

  .containerStyle{
    background-color: red;
  }

  .containerStyle2{
  }